import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { Heading3 } from "../heading";
import Mono, { MonoBold, MonoUppercase } from "../mono";
import RatioBox from "../ratio-box";

const StyledCard = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  /* a:hover & {
    background-color: var(--color-yellow);
  } */
`;

// color
// color-burn
// color-dodge
// darken
// difference
// exclusion
// hard-light
// hue
// lighten
// luminosity
// multiply
// normal
// overlay
// saturation
// screen
// soft-light

const StyledImage = styled.div`
  position: relative;
  height: inherit;
  user-select: none;
  overflow: hidden;

  background-image: url(${props => props.image && props.image});
  background-size: cover;
  background-position: 50% 50%;
  // background-blend-mode: luminosity;
  // opacity: 0.5;
  // filter: brightness(95%);
  // filter: contrast(140%);
  // background-color: var(--color-${props => props.bgColor && props.bgColor});
  // transition-property: background-color;
  // transition-duration: 0.1s;
  // a:hover & {
  //   background-color: var(--color-blue);
  // }
`;

const StyledImageHover = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  user-select: none;
  overflow: hidden;
  background-color: var(--color-${props => props.bgColor && props.bgColor});
  mix-blend-mode: screen;
  // opacity: 0;

  // background-image: url(${props => props.image && props.image});
  // background-size: cover;
  // background-position: 50% 50%;
  // background-blend-mode: luminosity;
  // background-color: var(--color-blue);
  transition-property: background-color;
  transition-duration: 0.1s;

  a:hover & {
    opacity: 1;
    background-color: var(--color-blue);
  }
`;

const StyledContent = styled.div`
  text-align: center;
  padding: 0;
  margin: 0 auto;
  color: var(--color-black);
  padding: 10px 10px;
  ${props => props.bgColor && `background-color: var(--color-${props.bgColor});`}
  ${props => props.color && `
    color: ${props.color};
    * {
      color: ${props.color};
    }
  `}
`;

const StyledHeading = styled(Heading3)`
  color: var(--color-white);
`;

class Card extends React.Component {
  render() {
    return (
      <StyledCard>
        <RatioBox ratio="881/512">
          {this.props.image && (
            <StyledImage image={this.props.image} bgColor={this.props.color2}>
              {this.props.imageHover && (
                <StyledImageHover image={this.props.imageHover} bgColor={this.props.color2} />
              )}
            </StyledImage>
          )}
        </RatioBox>
        <StyledContent bgColor={this.props.color1} color={this.props.color1fg}>
          {this.props.author && (
            <StyledHeading as="h3">{this.props.author}</StyledHeading>
          )}
          {/* {this.props.title && (
            <MonoUppercase>{this.props.title}</MonoUppercase>
          )} */}
        </StyledContent>
      </StyledCard>
    );
  }
}

export default Card;
