import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const Table = styled.table`
  position: relative;
  width: 100%;
  color: currentColor;
  margin: 0 auto;
`;

export const Tr = styled.tr`
  td:last-child {
    padding-right: 0;
  }
`;

export const Td = styled.td`
  vertical-align: top;
  text-align: left;
  padding: 1em 20px 0 0;

  ${breakpoint("lg")`
    padding-right: 40px;
  `}

  ${(props) => props.nowrap && `white-space: nowrap;`}
`;

export default Table;

