import React, { Component } from "react";
import sizeMe from "react-sizeme";

class SizeAware extends Component {
    render() {
        return <div>{this.props.children}</div>;
    }
}

export default sizeMe({ monitorHeight: true })(SizeAware);
