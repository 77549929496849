import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { Heading3 } from "../heading";
import Mono, { MonoBold, MonoUppercase } from "../mono";
import RatioBox from "../ratio-box";

const StyledCard = styled.div`
  position: relative;
  z-index: -1;
  overflow: hidden;

  /* a:hover & {
    background-color: var(--color-yellow);
  } */
`;

// color
// color-burn
// color-dodge
// darken
// difference
// exclusion
// hard-light
// hue
// lighten
// luminosity
// multiply
// normal
// overlay
// saturation
// screen
// soft-light

const StyledImage = styled.div`
  position: relative;
  height: inherit;
  user-select: none;
  overflow: hidden;
  border-bottom: 2px solid var(--color-black);

  background-image: url(${props => props.image && props.image});
  background-size: cover;
  background-position: 50% 50%;
  background-color: var(--color-yellow);

  a:hover & {
    background-blend-mode: screen;
    background-color: var(--color-yellow);
  }
`;

const StyledImageHover = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  user-select: none;
  overflow: hidden;
  opacity: 0;

  background-image: url(${props => props.image && props.image});
  background-size: cover;
  background-position: 50% 50%;
  background-color: var(--color-yellow);
  background-blend-mode: screen;
  background-color: var(--color-yellow);

  a:hover & {
    opacity: 1;
  }
`;

const StyledContent = styled.div`
  text-align: center;
  padding: 0;
  margin: 0 auto;
  color: var(--color-black);
  padding: 10px 10px;
`;

const StyledHeading = styled(Heading3)`
  color: var(--color-black);
`;

class SquareCard extends React.Component {
  render() {
    return (
      <StyledCard>
        <RatioBox ratio="881/811">
          {this.props.image && (
            <StyledImage image={this.props.image}>
              {this.props.imageHover && (
                <StyledImageHover image={this.props.imageHover} />
              )}
            </StyledImage>
          )}

        </RatioBox>
        <StyledContent>
          {this.props.author && (
            <StyledHeading as="h3">{this.props.author}</StyledHeading>
          )}
          {/* {this.props.title && (
            <MonoUppercase>{this.props.title}</MonoUppercase>
          )} */}
        </StyledContent>
      </StyledCard>
    );
  }
}

export default SquareCard;
