import React from "react";

// import Layout from "../components/layout";
import SEO from "../components/seo";
import Content from "../components/content";
import { Heading2 } from "../components/heading";

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <Content>
      <Heading2>404</Heading2>
      <p>You just hit a page that doesn&#39;t exist :(</p>
      <a href="/">Back to homepage</a>
    </Content>
  </React.Fragment>
);

export default NotFoundPage;
