/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onRouteUpdate = function ({ location, prevLocation }) {
    // console.log("onRouteUpdate");
    document.body.setAttribute('data-path', location.pathname);
    let path = location.pathname;
    if ((path.includes('/en/programme/') && path !== "/en/programme/") || (path.includes('/fi/programme/') && path !== "/fi/programme/")) {
        document.body.setAttribute('data-programme', 'true');
    } else {
        document.body.setAttribute('data-programme', 'false');
    }


    const scroller = document.getElementById("scroller");
    const parallax = document.getElementsByClassName("parallax")[0];

    if (scroller && scroller.scrollTo) {
        scroller.scrollTo(0, 0);
    }
    if (parallax && parallax.scrollTo) {
        parallax.scrollTo(0, 0);
    }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
    if (location.hash && location.hash.length && location.action === "POP") {
        // If an anchor link was clicked, we don't want to scroll at all
    } else {
        // window.scrollTo(0, 0);
    }
    return false;
};

export const onClientEntry = async () => {
    // console.log("onClientEntry");

    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            document.body.classList.add('user-is-tabbing');

            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    }

    function handleMouseDownOnce() {
        document.body.classList.remove('user-is-tabbing');

        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    }

    window.addEventListener('keydown', handleFirstTab);
};

export const onInitialClientRender = () => {
    // console.log("onInitialClientRender");
};
