import React, { Component } from "react";
import { graphql } from "gatsby";
import { Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import {Margin} from "styled-components-spacing";
import Obfuscate from "react-obfuscate";

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from '../components/block-text'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import Card from "../components/card";
import Content, { ContentDivider, ContentImage, ContentImageContainer } from "../components/content";
import { Heading2, Heading3 } from "../components/heading";
import Mono from "../components/mono";
import RatioBox from "../components/ratio-box";
import Footer from "../components/footer";
import Table, { Tr, Td  } from "../components/table";

export const query = graphql`
query TimetablePageQuery {
  site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    title
    _rawDescription
    keywords
  }
  timetablepage: sanityTimetable {
    _id
    title {
      fi
      en
    }
    _rawTimetableBody
    _rawTimetableBodyLocalized
    pdf {
      _key
      asset {
        url
      }
    }
  }
}
`;

const TimetablePage = ({ intl, ...props }) => {
  const { data, errors } = props;
  const locale = intl.locale || "en";

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <React.Fragment>
      <SEO
        title={`${intl.messages.menu_schedule}`}
        description={site._rawDescription[locale]}
        keywords={site.keywords}
        lang={locale}
      />
      <Content>
        <Heading2>{data.timetablepage.title[locale]}</Heading2>
        <StyledMono>
          {data.timetablepage.pdf && data.timetablepage.pdf.asset && (
            <StyledPdf>
              <StyledPdfLink href={data.timetablepage.pdf.asset.url}>
                Lataa PDF / Download PDF
              </StyledPdfLink>
            </StyledPdf>
          )}
          {data.timetablepage._rawTimetableBodyLocalized && data.timetablepage._rawTimetableBodyLocalized[locale] && (
            <BlockText blocks={data.timetablepage._rawTimetableBodyLocalized[locale]} />
          )}
          {data.timetablepage.timetableTable && (
            <StyledTable>
              Test
            </StyledTable>
          )}
        </StyledMono>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

const StyledTable = styled(Table)`
  margin: 50px auto;
  max-width: 666px;
  text-transform: uppercase;
`;

const StyledPdf = styled.div`
  padding-top: 1em;
  margin-bottom: 3.5em;
`;

const StyledPdfLink = styled.a`
  display: inline-block;
  color: var(--color-black) !important;
  border-bottom: 2px solid var(--color-black) !important;
`;

const StyledMono = styled(Mono)`
  p {
    margin-bottom: 2em;

    em {
      position: relative;
      font-family: var(--font-family-sans);
      font-weight: normal;
      font-style: normal;
      text-transform: uppercase;
      font-size: 0.6em;
      top: -0.5em;
      letter-spacing: 0.08em;
    }
  }
`;

export default injectIntl(TimetablePage);
