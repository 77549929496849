import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import SVG from "react-inlinesvg";

import { Heading3 } from "../heading";
import Mono, { MonoBold, MonoUppercase } from "../mono";
import RatioBox from "../ratio-box";

import Logo from "src/images/huiput-white2.svg";

const StyledCard = styled.div`
  position: relative;
  z-index: -1;
  overflow: hidden;
`;

const colorList = {
  0: 'blue',
  1: 'pink',
  2: 'red',
  3: 'yellow',
  4: 'purple',
  5: 'orange',
  6: 'green',
  7: 'blue',
  8: 'pink',
  9: 'red',
  10: 'yellow',
  11: 'purple',
  12: 'orange'
}

const StyledBackground = styled.div`
  position: relative;
  height: inherit;
  user-select: none;
  overflow: hidden;
  background-color: var(--color-yellow);
  ${props => props.cardKey >= 0 && `
    background-color: var(--color-${colorList[props.cardKey+1]});
  `}
  color: #fff;

  svg {
    position: absolute;
    width: 40%;
    max-width: 178px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;


const StyledContent = styled.div`
  text-align: center;
  padding: 0;
  margin: 0 auto;
  color: var(--color-white);
  padding: 10px 10px;
  ${props => props.cardKey >= 0 && `
    background-color: var(--color-${colorList[props.cardKey]});
  `}
`;


const StyledHeading = styled(Heading3)`
  color: inherit;
`;

class TbaCard extends React.Component {
  render() {
    return (
      <StyledCard>
        <RatioBox ratio="881/512">
          <StyledBackground cardKey={this.props.cardKey}>
            <SVG src={Logo} />
          </StyledBackground>
        </RatioBox>
        <StyledContent cardKey={this.props.cardKey}>
          <StyledHeading as="h3">TBA</StyledHeading>
        </StyledContent>
      </StyledCard>
    );
  }
}

export default TbaCard;
