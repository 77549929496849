// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-programme-js": () => import("./../../../src/pages/programme.js" /* webpackChunkName: "component---src-pages-programme-js" */),
  "component---src-pages-timetable-js": () => import("./../../../src/pages/timetable.js" /* webpackChunkName: "component---src-pages-timetable-js" */),
  "component---src-templates-speaker-js": () => import("./../../../src/templates/speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */),
  "component---src-templates-workshop-js": () => import("./../../../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */)
}

