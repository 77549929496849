import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const StyledRatioBox = styled.div`
  position: relative;
  width: 100%;
  color: currentColor;

  ${props => props.image && `
    background-image: url(${props.image});
    background-size: cover;
    background-position: 50% 50%;
  `}
`;

const StyledRatioBoxPadding = styled.div`
  position: relative;
  z-index: 100;
  padding-top: calc((${props => props.h} / ${props => props.w}) * 100%);
  ${breakpoint("md")`
    padding-top: calc((${props => props.h_md} / ${props => props.w_md}) * 100%);
  `}
`;

const StyledRatioBoxInner = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  h1, h2 {
    margin-bottom: 0;
  }

  ${props => props.flex && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  background-color: #ffffff;
  ${props => props.bgColor && `background-color: var(--color-${props.bgColor});`}
  ${props => props.fgColor && `
    color: var(--color-${props.fgColor});
    * {
      color: var(--color-${props.fgColor});
    }
  `}
`;

class RatioBox extends React.Component {
  render() {
    // default ratio
    const r = this.props.ratio.split("/");
    // optional ratio for medium and up
    if (this.props.ratio_md !== undefined) {
      var r_md = this.props.ratio_md.split("/");
    } else {
      var r_md = r;
    }

    return (
      <StyledRatioBox image={this.props.image}>
        <StyledRatioBoxPadding ratio={this.props.ratio} w={r[0]} h={r[1]} w_md={r_md[0]} h_md={r_md[1]}/>
        <StyledRatioBoxInner flex={this.props.flex} bgColor={this.props.bgColor} fgColor={this.props.fgColor}>
          {this.props.children}
        </StyledRatioBoxInner>
      </StyledRatioBox>
    );
  }
}

export default RatioBox;
