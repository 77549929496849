import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { graphql } from "gatsby";
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Grid from "styled-components-grid";

import BlockText from '../components/block-text'
import Container from "../components/container";
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import GraphQLErrorList from "../components/graphql-error-list";

import Content, { ContentDivider, ContentHeader, ContentImage } from "../components/content";
import { Heading2 } from "../components/heading";
import Mono, { MonoUppercase } from "../components/mono";
import RatioBox from "../components/ratio-box";
import SEO from "../components/seo";
import { FixedImage } from "../components/layout";

export const query = graphql`
  query SpeakerTemplateQuery($id: String!) {
    speaker: sanitySpeaker(id: { eq: $id }) {
      id
      slug {
        current
      }
      title {
        fi
        en
      }
      author
      color1
      color2
      publishedAt
      mainImage {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      galleryImages {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      _rawBody
      website
      instagram
      twitter
      _rawEmbedCode
    }
  }
`;

const SpeakerTemplate = ({ intl, ...props }) => {
  const { data, errors } = props;
  const node = data && data.speaker;
  const locale = intl.locale || "en";

  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {node && <SEO title={node.author || "Untitled"} locale={locale} lang={locale} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Grid bottom={false}>
        <Grid.Unit size={{ sm: 1 / 1, md: 1 / 2 }}>
          <StyledRatioBox>
            <RatioBox ratio="1/1">
              <StyledContentImage src={imageUrlFor(buildImageObj(node.mainImage))
                .width(960)
                .height(960)
                .auto('format')
                .quality(80)
                .saturation(-100)
                .url()} overlayColor={node.color2} />
            </RatioBox>
          </StyledRatioBox>
        </Grid.Unit>
        <Grid.Unit size={{ xs: 1 / 1, md: 1 / 2 }}>
          <StyledContent bgColor={node.color1} fgColor="white">
            <StyledContentHeader>
              <Heading2 fgColor="white">{node.author}</Heading2>
              {node.title && (
                <StyledTitle>
                  <MonoUppercase>{node.title[intl.locale]}</MonoUppercase>
                </StyledTitle>
              )}
            </StyledContentHeader>
            <Mono>
              <BlockText blocks={node._rawBody[intl.locale]} />
              {(node.website || node.instagram || node.twitter) && (
                <StyledWebsiteLink>
                  {node.website && (
                    <a href={node.website} target="_blank" className="button-link">WWW</a>
                  )}
                  {node.instagram && (
                    <a href={node.instagram} target="_blank" className="button-link">Instagram</a>
                  )}
                  {node.twitter && (
                    <a href={node.twitter} target="_blank" className="button-link">Twitter</a>
                  )}

                </StyledWebsiteLink>
              )}
            </Mono>
          </StyledContent>
          {(node.galleryImages && node.galleryImages.length > 1) && (
            <React.Fragment>
              <StyledGalleryComponentWrapper overlayColor={node.color1}>
                <GalleryComponent node={node} locale={locale} />
              </StyledGalleryComponentWrapper>
            </React.Fragment>
          )}
        </Grid.Unit>
      </Grid>
      <FixedImagePortal>
        <FixedImage fixedImageSrc={imageUrlFor(buildImageObj(node.mainImage))
          .width(1200)
          .height(1200)
          .auto('format')
          .quality(80)
          .saturation(-100)
          .url()} overlayColor={node.color2} />
      </FixedImagePortal>
      <StyledBackground />
    </React.Fragment>
  );
};

class FixedImagePortal extends React.Component {
  render() {
    const domNode = document.getElementsByTagName('body')[0];

    return ReactDOM.createPortal(
      this.props.children,
      domNode
    );
  }
}

const StyledContentDivider = styled(ContentDivider)`
  ${breakpoint("md")`
    display: none;
  `}
`;

const StyledBackground = styled.div`
  position: fixed;
  z-index: -10;
  width: 100%;
  height: 100%;
`;

const StyledContentImage = styled(ContentImage)`
  ${props => props.overlayColor && `
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-${props.overlayColor});
      mix-blend-mode: screen;
    }
  `}
`;

const StyledContent = styled(Content)`
  ${breakpoint("md")`
    border-left: 7px solid var(--color-white);
  `}
`;

const StyledTitle = styled.div`
  margin-top: 20px;
`;

const StyledContentHeader = styled(ContentHeader)`
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--color-white);

  ${breakpoint("md")`
    margin-top: 0;
    margin-bottom: 50px;
  `}
`;

const StyledRatioBox = styled.div`
  ${breakpoint("md")`
    display: none;
  `}
`;

const StyledWebsiteLink = styled.div`
  padding-top: 30px;

  a {
    margin: 0 10px !important;
  }
`;

const StyledGalleryComponentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${breakpoint("md")`
    border-left: 7px solid var(--color-white);
  `}

  .react-photo-gallery--gallery {
    margin: 0 -4px 0 -2px;

    ${props => props.overlayColor && `
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-${props.overlayColor});
        mix-blend-mode: screen;
        pointer-events: none;
      }
    `}

    img {
      filter: grayscale(100%);
      margin: 1px !important;
      display: block;
    }
  }
`;

const GalleryComponent = (props) => {
  const { node } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const gallery = [];

  const customStyles = {
    blanket: (base, state) => ({
      ...base,
      background: 'var(--color-yellow) !important'
    }),
    header: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    headerClose: (base, state) => ({
      ...base,
      color: 'white !important'
    }),
    navigationPrev: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    navigationNext: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    footer: (base, state) => ({
      ...base,
      background: 'none !important'
    }),
    footerCount: (base, state) => ({
      ...base,
      color: 'white !important',
      fontSize: '14px !important',
      textTransform: 'uppercase',
      letterSpacing: '0.03em'
    })
  }

  node.galleryImages.map((image, index) => {
    const imageUrl = imageUrlFor(buildImageObj(image))
      .width(1200)
      .maxWidth(1200)
      .maxHeight(1200)
      .auto('format')
      .quality(70)
      .url();

    const obj = {
      src: imageUrl,
      width: image.asset.fixed.width,
      height: image.asset.fixed.height
    }
    gallery.push(obj);
  });


  return (
    <React.Fragment>
      <Gallery photos={gallery} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal allowFullscreen={false} onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              styles={customStyles}
              hideControlsWhenIdle={false}
              views={gallery.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  );
};

export default injectIntl(SpeakerTemplate);
