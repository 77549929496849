import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";

import {
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import Card from "../components/card";
import TbaCard from "../components/card/tba";
import { PageHeading } from "../components/heading";
import { ContentDivider } from "../components/content";
import Layout, { theme } from "../containers/layout";
import Footer from "../components/footer";


export const query = graphql`
  fragment Speakers on SanitySpeaker {
    id
    slug {
      current
    }
    title {
      fi
      en
    }
    author
    color1
    color2
    publishedAt
    mainImage {
      _key
      _type
      caption
      alt
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
        id
        url
        fixed {
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
        fluid {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    galleryImages {
      _key
      _type
      caption
      alt
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
        id
        url
        fixed {
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
        fluid {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    _rawBody
    website
    _rawEmbedCode
  }

  fragment Workshops on SanityWorkshop {
    id
    slug {
      current
    }
    title {
      fi
      en
    }
    author
    color1
    color2
    publishedAt
    mainImage {
      _key
      _type
      caption
      alt
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
        id
        url
        fixed {
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
        fluid {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    galleryImages {
      _key
      _type
      caption
      alt
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
        id
        url
        fixed {
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
        fluid {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    _rawBody
    website
    _rawEmbedCode
  }

  query ProgrammePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      _rawDescription
      keywords
    }
    keynoteSpeakers: allSanitySpeakerListing {
      edges {
        node {
          id
          keynoteSpeakers {
            ...Speakers
          }

        }
      }
    }
    speakers: allSanitySpeakerListing {
      edges {
        node {
          id
          speakers {
            ...Speakers
          }
        }
      }
    }
    workshops: allSanitySpeakerListing {
      edges {
        node {
          id
          workshops {
            ...Workshops
          }
        }
      }
    }
  }
`;

const ProgrammePage = ({ intl, ...props }) => {
  const { data, errors } = props;
  const locale = intl.locale || "en";

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const keynoteSpeakerNodes = (data || {}).keynoteSpeakers
    ? data.keynoteSpeakers.edges[0].node.keynoteSpeakers
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const speakerNodes = (data || {}).speakers
    ? data.speakers.edges[0].node.speakers
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const workshopNodes = (data || {}).workshops
  ? data.workshops.edges[0].node.workshops
    .filter(filterOutDocsWithoutSlugs)
    .filter(filterOutDocsPublishedInTheFuture)
  : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const totalSpeakersAmount = 6;
  const totalCurrentAmount = (keynoteSpeakerNodes && keynoteSpeakerNodes.length) + (speakerNodes && speakerNodes.length);
  const placeholderAmount = totalSpeakersAmount - totalCurrentAmount;
  const totalWorkshopsAmount = 3;
  const totalCurrentWorkshopAmount = workshopNodes.length;
  const workshopPlaceholderAmount = totalWorkshopsAmount - totalCurrentWorkshopAmount;

  let placeholders = [];
  for (var i = 0; i < placeholderAmount; i++) {
    placeholders.push(
      <BorderGridCol key={`tba-${i}`} size={{ xs: 1 / 1, sm: 1 / 2, md: 1 / 3 }}>
        <CardComponent locale={locale} cardKey={i} />
      </BorderGridCol>
    );
  }
  let workshopPlaceholders = [];
  for (var i = 0; i < workshopPlaceholderAmount; i++) {
    workshopPlaceholders.push(
      <BorderGridCol key={`tba-${i}`} size={{ xs: 1 / 1, sm: 1 / 2, md: 1 / 3 }}>
      <CardComponent locale={locale} cardKey={i+3} />
      </BorderGridCol>
    );
  }

  return (
    <React.Fragment>
      <SEO
        title={`${intl.messages.menu_programme}`}
        description={site._rawDescription[locale]}
        keywords={site.keywords}
        lang={locale}
      />
      <PageHeading bgColor="red">{intl.messages.speakers}</PageHeading>
      <StyledListingWrapper>
        <BorderGrid>
          {(keynoteSpeakerNodes && keynoteSpeakerNodes.length > 0) && (
            <React.Fragment>
              {keynoteSpeakerNodes.map((node, index) => {
                return (
                  <BorderGridCol key={`node-${node.id}`} size={{ xs: 1 / 1, sm: 1 / 2, md: 1 / 3 }}>
                    <CardComponent node={node} locale={locale} />
                  </BorderGridCol>
                )
              })}
            </React.Fragment>
          )}
          {(speakerNodes && speakerNodes.length > 0) && (
            <React.Fragment>
              {speakerNodes.map((node, index) => {
                return (
                  <BorderGridCol bgColor={node.color1} key={`node-${node.id}`} size={{ xs: 1 / 1, sm: 1 / 2, md: 1 / 3 }}>
                    <CardComponent node={node} bgColor="var(--color-blue)" locale={locale} />
                  </BorderGridCol>
                )
              })}
            </React.Fragment>
          )}
          {placeholders.length > 0 && (
            <React.Fragment>{placeholders}</React.Fragment>
          )}
        </BorderGrid>
      </StyledListingWrapper>
      <PageHeading bgColor="orange">{intl.messages.workshops}</PageHeading>
      <StyledListingWrapper>
        <BorderGrid>
          {(workshopNodes && workshopNodes.length > 0) && (
            <React.Fragment>
              {workshopNodes.map((node, index) => {
                return (
                  <BorderGridCol bgColor={node.color1} key={`node-${node.id}`} size={{ xs: 1 / 1, sm: 1 / 2, md: 1 / 3 }}>
                    <CardComponent node={node} bgColor="var(--color-blue)" locale={locale} />
                  </BorderGridCol>
                )
              })}
            </React.Fragment>
          )}
          {workshopPlaceholders.length > 0 && (
            <React.Fragment>{workshopPlaceholders}</React.Fragment>
          )}
          </BorderGrid>
      </StyledListingWrapper>
      <Footer />
    </React.Fragment>
  );
};

const StyledListingWrapper = styled.div`
  display: block;
`;

const CardComponent = (props) => {
  const { node, cardKey, locale } = props;

  return node ? (
    <Link to={`/programme/${node.slug.current}/`} style={{display: 'block'}}>
      <Card href={`/programme/${node.slug.current}/`} image={
        imageUrlFor(buildImageObj(node.mainImage))
          .width(960)
          .height(592)
          .auto('format')
          .quality(80)
          .saturation(-100)
          .url()}
        imageHover={
          imageUrlFor(buildImageObj(node.mainImage))
            .width(960)
            .height(592)
            .auto('format')
            .quality(80)
            .saturation(-100)
            .url()}
        author={node.author}
        color1={node.color1}
        color2={node.color2}
        color1fg='white'
        color2fg='white'
        title={node.title ? node.title[locale] : undefined}
      />
    </Link>
  ) : (<TbaCard cardKey={cardKey} />)
}

export default injectIntl(ProgrammePage);
