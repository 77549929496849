import React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";

const StyledBorderGrid = styled(Grid)`
  position: relative;
  width: calc(100% + 2px);
`;

const StyledBorderGridWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
`;

const StyledBorderGridBottom = styled.div`
  margin-top: -3px;
`;

const StyledBorderGridCol = styled(Grid.Unit)`
  position: relative;
  background-blend-mode: none;
  ${props => props.bgColor && `background-color: var(--color-${props.bgColor});`}
`;

const StyledRightBorder = styled.div`
  position: absolute;
  top: 0;
  right: -1px;
  width: 2px;
  height: 100%;
  background-color: var(--color-black);
`;

const StyledTopBorder = styled.div`
  position: absolute;
  top: -2px;
  left: 0;
  width: 110vw;
  height: 2px;
  background-color: var(--color-black);
`;

class BorderGrid extends React.Component {
  render() {
    return (
      <StyledBorderGridWrapper>
        <StyledBorderGrid {...this.props}>{this.props.children}</StyledBorderGrid>
        <StyledBorderGridBottom />
      </StyledBorderGridWrapper>
    );
  }
}

export class BorderGridCol extends React.Component {
  render() {
    return (
      <StyledBorderGridCol {...this.props} bgColor={this.props.bgColor}>
        {this.props.children}
        {/*
        <StyledTopBorder />
        <StyledRightBorder />
        */}
      </StyledBorderGridCol>
    );
  }
}

export default BorderGrid;
