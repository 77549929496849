import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { injectIntl, Link } from "gatsby-plugin-intl";
import SVG from "react-inlinesvg";

// import { BoldCopy, BoldCopyCss } from "./content";

import GrafiaLogo from "../images/grafia.svg";
// import AntalisLogo from "../images/logo/antalis.png";
// import BalansiaLogo from "../images/logo/balansia.svg";
// import HabitareLogo from "../images/logo/habitare.svg";
// import IlmapallokeskusLogo from "../images/logo/ilmapallokeskus.svg";
// import OutdoorLogo from "../images/logo/outdoor.svg";
// import PogoLogo from "../images/logo/pogo-white.svg";
// import PureWasteLogo from "../images/logo/pure-waste-2.svg";
// import SoundLogo from "../images/logo/sound-engine-black.svg";
// import DeedLogo from "../images/logo/deed.svg";
// import SanomaLogo from "../images/logo/sanoma.png";
// import HakolaLogo from "../images/logo/hakola.svg";
import HdwLogo from "../images/logo/hdw.svg";
import HdWeeklyLogo from "../images/logo/hdweekly.svg";
import MarkprintLogo from "../images/logo/markprint_2023.svg";
import PagaLogo from "../images/logo/paga.svg";
import LsbLogo from "../images/logo/lsb.svg";
import DialabLogo from "../images/logo/dialab.svg";
import OutdoorFinlandLogo from "../images/logo/outdoorfinland-new.svg";
import VisualMonkeysLogo from "../images/logo/visualmonkeys.svg";
import KukkapuroVintageLogo from "../images/logo/kukkapuro-vintage.svg";
import BpfLogo from "../images/logo/bpf-logo-slogan.svg";
import DeedCreativeLogo from "../images/logo/deed-creative.svg";
import PureWasteLogo from "../images/logo/purewaste.svg";
import ALehdetLogo from "../images/logo/a-lehdet.svg";
import EeroAarnioLogo from "../images/logo/eeroaarnio.svg";

// import TomiLogo from "../images/logo/tl-white.png";
import Instagram from "../images/social-instagram-3.svg";

const Footer = ({ intl }) => {
    // console.log(intl)
    return (
        <StyledFooter>
            <StyledTop>
                <StyledTopCol>
                    <StyledInstagram>
                        <a href="https://www.instagram.com/huiputfestival/" title="Instagram" target="_blank">
                            <SVG src={Instagram} />
                        </a>
                    </StyledInstagram>
                </StyledTopCol>
                <StyledTopCol>
                    <StyledUppercase as="p">
                        #huiputcreativefestival
                        {/* {intl.messages.date} {intl.messages.location_1} {intl.messages.location_2} */}
                    </StyledUppercase>
                </StyledTopCol>
                <StyledTopCol>
                    <StyledGrafiaLogo>
                        <a href="https://grafia.fi/" title="Grafia" target="_blank">
                            <SVG src={GrafiaLogo} />
                        </a>
                    </StyledGrafiaLogo>
                </StyledTopCol>
            </StyledTop>
            <StyledDivider />
            <StyledUppercase as={StyledHeading}>
                {intl.messages.partners}
            </StyledUppercase>
            <StyledLogoGrid>
                <StyledLogoGridCol>
                    <a href="https://www.helsinkidesignweek.com/" title="Helsinki Design Week" target="_blank">
                        <StyledHdwLogo src={HdwLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.helsinkidesignweekly.com/" title="Helsinki Design Weekly" target="_blank">
                        <StyledHdWeeklyLogo src={HdWeeklyLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>

                <StyledLogoGridCol>
                    <a href="https://suomenulkomainosliitto.fi/" title="Outdoor Finland" target="_blank">
                        <StyledOutdoorFinlandLogo src={OutdoorFinlandLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.a-lehdet.fi/" title="A-lehdet" target="_blank">
                        <StyledALehdetLogo src={ALehdetLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>

            </StyledLogoGrid>
            <StyledLogoGrid>
                <StyledLogoGridCol>
                    <a href="https://www.lsb.fi/" title="LSB" target="_blank">
                        <StyledLsbLogo src={LsbLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.markprint.fi/" title="Markprint" target="_blank">
                        <StyledMarkprintLogo src={MarkprintLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://fi.aarniooriginals.com/" title="Eero Aarnio Originals" target="_blank">
                        <StyledEeroAarnioLogo src={EeroAarnioLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://deedcreative.com/" title="DEED Creative" target="_blank">
                        <StyledDeedCreativeLogo src={DeedCreativeLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
            </StyledLogoGrid>

            <StyledLogoGrid>
{/*                <StyledLogoGridCol>
                    <StyledKukkapuroVintageLogo src={KukkapuroVintageLogo} type="image/svg+xml" />
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://www.bpf-finland.fi/" title="Beverage Partners Finland" target="_blank">
                        <StyledBpfLogo src={BpfLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
*/}                <StyledLogoGridCol>
                    <a href="https://www.purewaste.com/" title="Pure Waste" target="_blank">
                        <StyledPureWasteLogo src={PureWasteLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
                <StyledLogoGridCol>
                    <a href="https://paga.fi/" title="Pågå" target="_blank">
                        <StyledPagaLogo src={PagaLogo} type="image/svg+xml" />
                    </a>
                </StyledLogoGridCol>
            </StyledLogoGrid>

            <StyledDivider />
            <StyledUppercase as={StyledCentered}>
                {intl.messages.previous_festivals}: {" "}
                <a href="http://festival.vuodenhuiput.fi/2017/" className="link">2017</a> | <a href="http://festival.vuodenhuiput.fi/2018/" className="link">2018</a> | <a href="http://festival.vuodenhuiput.fi/2019/" className="link">2019</a> | <a href="https://www.huiputfestival.fi/2020/" className="link">2020</a> | <a href="https://www.huiputfestival.fi/2021/" className="link">2021</a> | <a href="https://www.huiputfestival.fi/2022/" className="link">2022</a>
            </StyledUppercase>
        </StyledFooter>
    );
};

const StyledInstagram = styled.div`
    max-height: 35px;
    max-height: 35px;

    svg {
        width: 35px;
        height: 35px;
        rect, circle {
            fill: none;
        }
    }

    ${breakpoint("lg")`
        max-height: 40px;

        svg {
            width: 40px;
            height: 40px;
        }
    `}
`;

const StyledHdwLogo = styled(SVG)`
    max-height: 50px !important;

    ${breakpoint("lg")`
        max-height: 60px !important;
    `}
`;
const StyledHdWeeklyLogo = styled(SVG)`
    max-height: 60px !important;

    ${breakpoint("lg")`
        max-height: 70px !important;
    `}
`;
const StyledMarkprintLogo = styled(SVG)`
    max-height: 25px !important;

    ${breakpoint("lg")`
        max-height: 32px !important;
    `}
`;
const StyledPagaLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 150px !important;
    `}
`;
const StyledLsbLogo = styled(SVG)`
    max-height: 50px !important;

    ${breakpoint("lg")`
        max-height: 70px !important;
    `}
`;
const StyledDialabLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 110px !important;
    `}
`;
const StyledOutdoorFinlandLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 180px !important;
    `}
`;
const StyledVisualMonkeysLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;
const StyledKukkapuroVintageLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;
const StyledBpfLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;
const StyledDeedCreativeLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;
const StyledPureWasteLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;
const StyledALehdetLogo = styled(SVG)`
    max-height: 80px !important;

    ${breakpoint("lg")`
        max-height: 140px !important;
    `}
`;

const StyledEeroAarnioLogo = styled(SVG)`
    max-height: 100px !important;

    ${breakpoint("lg")`
        max-height: 150px !important;
    `}
`;

const StyledGrafiaLogo = styled.div`
    width: 80px;
    margin: 20px auto;

    svg {
        width: 100%;
        height: auto;
    }

    ${breakpoint("lg")`
        width: 100px;
        margin: 0 auto;
    `}
`;

const StyledUppercase = styled.div`
    text-transform: uppercase;
`;

const StyledFooter = styled.div`
    color: var(--color-white);
    background-color: var(--color-pink);
    padding: 50px 25px 120px;
    letter-spacing: 0.06em;
    overflow: hidden;
    font-size: 18px;

    ${breakpoint("lg")`
        padding: 50px;
    `}

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    a {
        color: inherit;
        &:hover {
            &:after {
                background-color: var(--color-white);
            }
        }
    }


    *:focus {
        outline: none;
        border: 0 solid transparent;
        border-radius: 3px;
        box-shadow: 0 0 0 3px var(--color-white);
    }


    // inline svg colors
    svg {
        *[fill="#000000"], *[fill="#000"], *:not([fill]) {
            fill: var(--color-white);
        }
        *[stroke="#000000"], *[stroke="#000"] {
            stroke: var(--color-white);
        }
        * {
            color: var(--color-white);
        }
    }
`;

const StyledTop = styled.div`
    width: 100%;
    text-align: center;
   margin-bottom: 1em;

    ${breakpoint("md")`
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}
`;

const StyledTopCol = styled.div`
   p {
       padding-bottom: 0;
   }

    ${breakpoint("md")`
        flex-basis: 33%;
        text-align: center;

        p {
            padding-bottom: 0;
        }
    `}
`;

const StyledLogoGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    ${breakpoint("lg")`
        width: 100%;
        flex-wrap: nowrap;
    `}
`;

const StyledLogoGridCol = styled.div`
    padding: 0 20px;
    text-align: center;
    flex-basis: calc(100% / 3);

    ${breakpoint("md")`
        flex-basis: calc(100% / 7);
    `}

    ${breakpoint("md")`
        flex-basis: calc(100% / 7);

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    `}

    ${breakpoint("xl")`
        flex-basis: calc(100% / 8);
    `}



    a {
        display: block;
        margin: 0 auto;
    }

    img {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 30px;
        margin: 20px auto;

        ${breakpoint("lg")`
            width: auto;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0;
        `}
    }
    svg {
        width: auto;
        max-width: 120px;
        height: auto;
        max-height: 40px;
        margin: 20px auto;

        ${breakpoint("lg")`
            width: 100%;
            max-width: 240px;
            height: auto;
            max-height: 55px;
            margin: 0px;
        `}
    }
`;

const StyledDivider = styled.div`
    height: 0;
    margin: 40px 0;
`;

const StyledHeading = styled.p`
    padding-bottom: 20px;
    text-align: center;
`;

const StyledCentered = styled.p`
    text-align: center;
`;


export default injectIntl(Footer);
